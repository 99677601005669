.transactions{
    flex:2;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

section > h2 {
    font-size: 1.5rem;
    font-weight: medium;
}


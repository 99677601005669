@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300&display=swap");

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
select {
  outline: none !important;
}

.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(4, 114, 187);
  color: #fefefe;
  flex-direction: column;
}

.loading h1 {
  font-weight: bold;
  font-size: 2.3rem;
}

::-webkit-scrollbar {
  display: none;
}
* {
  scrollbar-width: none;
}

.main {
  display: flex;
  flex-direction: column;
  height: (100vh - 1em);
  height: (100vw - 1em);
  background-color: #eeeeee7d;
}

/* sections page  */

/* History  */

.form {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form .inputs {
  flex: 1;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  outline: none;
}

.form .inputs select {
  height: 50px;
  background-color: #fff;
  border: none;
  width: 30%;
  margin-right: 10px;
  border-bottom: 2px solid #aaa;
  outline: none;
}

.form .leftSide {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 50px;
  padding-left: 10px;
}

.leftSide p {
  font-size: 18px;
  color: #000;
  opacity: 0.6;
  font-weight: 500;
}

.filters {
  margin-bottom: 10px;
}

.listContent {
  max-height: 93vh;
}

.pagination {
  max-height: 5vh;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding-right: 100px;

  padding: 10px;
}

/* beneficiaries */
.beneficiaries .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 30px 20px;
}

.beneficiaries .heading select {
  height: 50px;
  background-color: #fff;
  border: none;
  width: 100%;
  margin-right: 10px;
  border-bottom: 1px solid #aaa;
}
.heading div {
  flex: 1;
}

.heading .addBtnSection {
  display: flex;
  justify-content: flex-end;
}

.fa {
  margin-right: 8px;
  font-size: 14px;
}

.PhoneInput {
  width: 100% !important;
  margin-top: 10px;
}

.success__page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: #f1f1f1;
}

@media only screen and (max-width: 1000px) {
  .success__box {
    width: 70%;
  }
}

.success__box {
  width: 40%;
  border-radius: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  min-height: 30%;
  padding: 30px;
  border-radius: 10px;
}

.success__title {
  font-weight: bold;
  font-size: 30px;
  margin: 10px;
}

.success_description {
  font-size: 15px;
  font-weight: 500;
  color: #555;
  text-align: center;
  margin-bottom: 10px;
}

.transactions {
  height: 75vh;
  overflow-y: scroll;
}

.accounts {
  padding: 9px;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  cursor: pointer;
}

.account {
  margin-right: 20px;
}
.account .details {
  min-width: 450px !important;
}

.verySmall {
  font-size: 11px;
  text-transform: capitalize;
}

.small {
  font-size: 11px;
}
.page-item {
  font-size: 12px;
  margin: 10px;
  padding: 10px;
}

.item--active {
  background-color: #037375 !important;
  font-size: 12px;
  margin: 10px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
}

.hover-class:hover {
  background-color: #037375 !important;
  color: #fff !important;
}

.CustomeUploadTable {
  background: #fff;
  box-shadow: 1px 1px 8px 6px #fafafa;
  padding: 10px;
  border-radius: 10px;
}

.CustomeUploadTable .searchUploadCustome {
  margin: 0px !important;
  height: fit-content;
  opacity: 1;
}

.CustomeUploadTable .searchUploadCustome {
  height: auto !important;
  background: #fafafa;
  box-shadow: 0px 0px;
  border-radius: 8px;
  min-height: fit-content;
  display: flex;
  align-items: center;
  /* padding-left: 15px; */
}

.CustomeUploadTable
  .MuiPaper-root.ForwardRef-root-1.MuiPaper-elevation1
  input::placeholder {
  color: #c7c7c7;
  opacity: 1;
  font-size: 15px !important;
  font-weight: 400;
}

.CustomeUploadTable input {
  height: 48px !important;
  background: #fafafa;
  box-shadow: 0px 0px;
  border-radius: 8px;
  min-height: fit-content;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border: 0px;
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0px;
  outline: 0px;
  box-shadow: 0px 0px transparent;
}

.CustomeUploadTable input:focus {
  border-color: transparent;
  outline: 0;
  background: #fafafa;
  box-shadow: 0px 0px transparent;
}

.CustomeUploadTable .rdt_TableRow {
  grid-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto auto;
  padding: 0px 5px;
}

.CustomeUploadTable .rdt_TableRow .rdt_TableCell > div {
  width: 100%;
}
.CustomeUploadTable .rdt_TableRow .rdt_TableCell:last-child > div {
  text-align: center;
}
.CustomeUploadTable .rdt_TableRow .rdt_TableCell {
  padding: 0px;
}

.CustomeUploadTable .searchUploadCustome input {
  padding: 0px;
  height: auto !important;
  margin: 0px;
}
.deleteIconUpload i:before {
  content: "\f1f8";
}
.CustomeUploadTable .rdt_TableRow .rdt_TableCell:nth-last-child(2) {
  max-width: 50px;
  min-width: 50px;
  justify-content: center;
}

.CustomeUploadTable .rdt_TableRow .rdt_TableCell:nth-last-child(2) > div {
  text-align: center;
}

.CustomeUploadTable .rdt_TableRow .rdt_TableCell:last-child {
  max-width: 50px;
  min-width: 50px;
  justify-content: center;
}

.deleteIconUpload i {
  margin: 0px;
  font-size: 25px;
  color: #434141;
}
.SucessIcon i {
  margin: 0px;
  font-size: 25px;
  color: #7dd062;
}

.ErrorIcon i {
  margin: 0px;
  font-size: 25px;
  color: red;
}
.custom-error input {
  border: 1px solid red;
  padding-right: 30px;
}

.Customeinputparrent input::placeholder {
  font-size: 14px;
}

.custom-error {
  position: relative;
}

.custom-error:before {
  content: "\f05a";
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -10px;
  font: normal normal normal 20px/1 FontAwesome;
  color: red;
}

.customeTableWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.CustomeAddCell i {
  margin: 0px;
  font-size: 20px;
  color: #434141;
}
/* MakeTransfer css */

.wrapperSwitchAndLable {
  margin: 0px !important;
  padding: 20px 15px;
  border: 1px solid #cccccc;
  display: flex;
  grid-gap: 20px;
  margin-bottom: 10px !important;
}

.wrapperSwitchAndLableNew {
  margin: 0px !important;
  padding: 20px 15px;
  /* border: 1px solid #cccccc; */
  display: flex;
  grid-gap: 20px;
  margin-bottom: 10px !important;
}

.wrapperSwitchAndLableNew .MuiFormControlLabel-label {
  color: #4c4c4c;
  font-weight: 400;
  font-size: 14px;
}

.wrapperSwitchAndLableNew .MuiSwitch-root {
  padding: 0px;
  border-radius: 100px;
  height: 32px;
  width: 55px;
}

.wrapperSwitchAndLableNew .MuiSwitch-root .MuiSwitch-switchBase {
  padding: 2px;
}

.wrapperSwitchAndLableNew
  .MuiSwitch-root
  .MuiSwitch-switchBase
  span.MuiSwitch-thumb {
  width: 28px;
  height: 28px;
}

.wrapperSwitchAndLableNew .MuiSwitch-root .MuiSwitch-track {
  background-color: #e5e5e5;
}

/*  */

.wrapperSwitchAndLable .MuiFormControlLabel-label {
  color: #4c4c4c;
  font-weight: 400;
  font-size: 14px;
}

.wrapperSwitchAndLable .MuiSwitch-root {
  padding: 0px;
  border-radius: 100px;
  height: 32px;
  width: 55px;
}

.wrapperSwitchAndLable .MuiSwitch-root .MuiSwitch-switchBase {
  padding: 2px;
}

.wrapperSwitchAndLable
  .MuiSwitch-root
  .MuiSwitch-switchBase
  span.MuiSwitch-thumb {
  width: 28px;
  height: 28px;
}

.wrapperSwitchAndLable .MuiSwitch-root .MuiSwitch-track {
  background-color: #e5e5e5;
}

.customeDesignwithdraw {
  padding: 0.5rem 1.5rem !important;
}

.withdrawTitle {
  font-size: 15px;
  color: #7f7f7f;
  font-weight: bolder;
}

.customeDesignwithdraw {
  display: grid !important;
}

.bacnkCheckBoxWrapper {
  padding: 11px 21px;
  background: #fafafa;
  border-radius: 10px;
  margin: 10px 0px;
}

.bacnkCheckBoxWrapper .wrapperBankCheckBox {
  padding: 11px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  grid-gap: 31px;
  background: #ffff;
  align-items: center;
  border: 1px solid #e8e8e8;
}

.bacnkCheckBoxWrapper .wrapperBankCheckBox input {
  width: 30px;
  height: 30px;
  opacity: 0;
}

.bacnkCheckBoxWrapper label {
  font-size: 15px;
  color: #4c4c4c;
}

.bacnkCheckBoxWrapper input:checked ~ .checkmark {
  background: #e6e6e6;
  border-radius: 10px;
}

.bacnkCheckBoxWrapper input:checked ~ .checkmark:before {
  content: "\f00c";
  background-color: #067375;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font: normal normal normal 14px/1 FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 17px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 30px;
  width: 30px; */
  height: 100%;
  width: 100%;
  background-color: #eee;
  pointer-events: none;
}

.wrapperBankCheckBoxwrapper {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.wrapperBankCheckBoxwrapper:focus-within {
  outline: thick solid rgba(13, 110, 253, 0.25);
}

.innerBankBox {
  padding: 11px 21px;
  background: #fafafa;
  border-radius: 10px;
  margin: 10px 0px;
}

.innerBankBox {
  padding: 0px 11px 0px 11px;
  background: #fafafa;
  border-radius: 10px;
  margin: 0px;
}

.innerBoxDesc {
  font-size: 14px;
  color: #8b8b8b;
  font-weight: 400;
}

.innerBankBox .w-full.mb-1 {
  margin: 10px 0px;
}

.innerBankBox .w-full.mb-1 {
  margin: 0px !important;
}

.innerBankBox .w-full.mb-1 input,
.innerBankBox .bg-white {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fff !important;
}

.owner {
  border: 0px;
  padding: 15px 16px;
  /* padding-left: 96px !important; */
  border-radius: 10px;
  margin: 7px 0px !important;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.innerBankBox .w-full.mb-1 input {
  border: 0px;
  padding: 15px 16px !important;
  padding-left: 96px;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fff !important;
}
.outerfieldBox {
  margin-bottom: 1px !important;
}

.bankNameDropDown,
.customInputStyle {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fafafa !important;
  color: #080707;
  font-size: 14px;
  height: 50px;
}
.customInputStyle:active,
.customInputStyle:hover,
.customInputStyle:focus-visible,
.customInputStyle:focus {
  border: none;
  outline: none;
}

.transferPaymentFormInput {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fafafa !important;
  color: #080707;
  font-size: 14px;
}

.outerfieldBox input,
.outerfieldBox select {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fafafa !important;
  color: #080707;
  font-size: 14px;
}
.outerfieldBoxWhite input,
.outerfieldBoxWhite select {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #ffffff !important;
  color: #080707;
  font-size: 14px;
}

.innerBankBox .bg-white {
  background-color: transparent;
}

.innerBankBox .bg-white input {
  border: 0px;
  padding: 0px !important;
  border-radius: 10px !important;
}

.innerBankBox .w-full.mb-1 input::placeholder {
  color: #cacaca;
  font-size: 14px;
}

.innerBankBox .bg-white small {
  color: #414141;
}

.innerBankBox .bg-white:last-child {
  background: #fafafa !important;
  padding: 0px !important;
  grid-gap: 5px;
}

.innerBankBox .bg-white:last-child input {
  background: transparent;
  padding: 15px 16px !important;
  background: #fff;
}

.innerBankBox .bg-white:last-child .px-1 {
  padding: 15px 16px !important;
  background: #fff;
  border-radius: 10px;
  min-height: 50px;
}

.scrollDiv {
  /* max-height: 350px; */
  overflow-y: scroll;
  margin-bottom: 15px;
}

.customeCacheDesign {
  padding: 0px !important;
}
.customeCacheDesign input.default\:ring-2 {
  margin: 0px !important;
}
.marg-0 {
  margin-bottom: 0 !important;
}
.pad-10 {
  padding: 14px !important;
}
.customDesign {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 13px !important;
}

.react-datepicker__month-container {
  width: 178px;
  height: 253px;
  border-radius: 92px;
  border: none !important;
}

.react-datepicker__month {
  height: 524px;
  width: 172px;
  display: flex;
  flex-flow: column;
  gap: 14px;
}

.react-datepicker {
  box-shadow: 0 0 20px 2px #00000054;
  border-radius: 20px;
  border: none !important;
}

.react-datepicker__input-container input {
  background: transparent !important;
}

.gray-datepicker {
  background: #fafafa !important;
  box-shadow: none !important;
}

.bacnkCheckBoxWrapper {
  padding: 11px 21px;
  background: #fafafa;
  border-radius: 10px;
  margin: 10px 0px;
}

.bacnkCheckBoxWrapper .wrapperBankCheckBox {
  padding: 11px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  grid-gap: 31px;
  background: #ffff;
  align-items: center;
  border: 1px solid #e8e8e8;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 30px;
  width: 30px; */
  height: 100%;
  width: 100%;
  background-color: #eee;
  pointer-events: none;
}

.bacnkCheckBoxWrapper .wrapperBankCheckBox input {
  width: 30px;
  height: 30px;
  opacity: 0;
}

.bacnkCheckBoxWrapper label {
  font-size: 15px;
  color: #4c4c4c;
}

.bacnkCheckBoxWrapper input:checked ~ .checkmark {
  background: #e6e6e6;
  border-radius: 10px;
}

.bacnkCheckBoxWrapper input:checked ~ .checkmark:before {
  content: "\f00c";
  background-color: #067375;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font: normal normal normal 14px/1 FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 17px;
}

.wrapperBankCheckBoxwrapper {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.otherRowStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}
.otherRowStyle .bacnkCheckBoxWrapper {
  width: 100%;
}
.otherRowStyle .outerfieldBox {
  border-radius: 10px;
  margin-bottom: 0px !important;
}
.otherRowStyle .bacnkCheckBoxWrapper .wrapperBankCheckBox .outerfieldBox input {
  padding: 0 !important;
  opacity: 1;
  width: 100%;
  background-color: transparent !important;
  margin: 0px !important;
}

.otherRowStyle .outerfieldBox label {
  display: none;
}

.customcheckbox {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  outline: none;
  position: relative;
  cursor: pointer;
  display: block;
  /* background-color: #fafafa; */
  background-color: #0000004d;
}

.customcheckbox:checked {
  background-color: #007a7e;
  position: relative;
}

.customcheckbox:checked::before {
  font-family: FontAwesome;
  content: "\f00c";
  color: white;
  font-size: 16px;
  position: absolute;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* withdraw checkbox */
.withdrawcheckbox {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.313rem;
  outline: none;
  position: relative;
  cursor: pointer;
  display: block;
  /* background-color: #fafafa; */
  background-color: #ebebeb;
}

.withdrawcheckbox:checked {
  background-color: #007a7e;
  position: relative;
}

.withdrawcheckbox:checked::before {
  font-family: FontAwesome;
  content: "\f00c";
  color: white;
  position: absolute;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* signup shadow */
.shadow-c {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
}

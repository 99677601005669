.addModal {
  width: 500px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
}

.closeBtn {
  cursor: pointer;
  float: right;
  margin-top: 5px;
  width: 40px;
}

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.6);
}

.react-confirm-alert-overlay {
  z-index: 1500 !important;
}

@media only screen and (max-width: 400px) {
  .react-confirm-alert {
    width: 100vw;
  }
}

@media only screen and (max-width: 700px) {
  .bank-container {
    padding: 10px;
  }

  .mainbar {
    display: inline-block;
    text-align: center;
  }

  .addBankBtn {
    margin-top: 20px;
  }

  .modalBackground {
    zoom: 0.7;
  }

  .ibanNumber {
    font-size: 0.9rem;
  }

  .contentContainer {
    display: inline-block;
  }
}

@media only screen and (max-width: 1200px) {
  .rightPart {
    margin-top: 10px !important;
  }
}
